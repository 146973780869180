import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import * as TWEEN from '@tweenjs/tween.js';

// Scene setup
const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
camera.position.set(12, 10, 10);

const renderer = new THREE.WebGLRenderer();
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);

// Controls setup
const controls = new OrbitControls(camera, renderer.domElement);
controls.target.set(0, 0, 0);
controls.minPolarAngle = 0;
controls.maxPolarAngle = Math.PI / 2;
controls.enablePan = false;
controls.update();

// Load textures
const textureLoader = new THREE.TextureLoader();
const backgroundTexture = textureLoader.load('../assets/image.jpg', texture => {
    scene.background = texture;
});
const screenTexture = textureLoader.load('../assets/linkedin_bc_logo.png');

// Load GLTF file
const loader = new GLTFLoader();
const url = new URL('../assets/arcaderoom.glb', import.meta.url);

let aboutmeCube, experienceCube, projectsCube, creditsCube, Monitor, arcadeMaterial, TVMaterial;

loader.load(url.href, gltf => {
    scene.add(gltf.scene);
    gltf.scene.position.set(0, 0, 0);

    // Get references to the cubes and materials
    aboutmeCube = gltf.scene.getObjectByName("aboutmeCube");
    experienceCube = gltf.scene.getObjectByName("experienceCube");
    projectsCube = gltf.scene.getObjectByName("projectsCube");
    creditsCube = gltf.scene.getObjectByName("creditsCube");
    Monitor = gltf.scene.getObjectByName("Monitor");

    // Traverse through all objects to find materials
    gltf.scene.traverse(object => {
        if (object.isMesh) {
            if (object.material.name === "arcadescreen") {
                arcadeMaterial = object;
            } else if (object.material.name === "TVscreen") {
                TVMaterial = object;
            }
        }
    });

    if (Monitor) {
        console.log("Monitor position:", Monitor.position);
    } else {
        console.error("Monitor not found in the GLTF file");
    }

    if (arcadeMaterial) {
        console.log("Arcade Screen position:", arcadeMaterial.position);
    } else {
        console.error("Arcade Screen not found in the GLTF file");
    }

    if (TVMaterial) {
        console.log("TV Screen position:", TVMaterial.position);
    } else {
        console.error("TV Screen not found in the GLTF file");
    }

    if (aboutmeCube || experienceCube || projectsCube || creditsCube) {
        renderer.domElement.addEventListener('click', onMouseClick, false);
    } else {
        console.error("Cubes not found in the GLTF file");
    }

    controls.update();
}, undefined, error => {
    console.error('An error happened:', error);
});

// Handle mouse clicks
function onMouseClick(event) {
    const mouseX = (event.clientX / window.innerWidth) * 2 - 1;
    const mouseY = -(event.clientY / window.innerHeight) * 2 + 1;

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera({ x: mouseX, y: mouseY }, camera);

    const intersects = [
        { cube: aboutmeCube, name: 'aboutmeCube' },
        { cube: experienceCube, name: 'experienceCube' },
        { cube: projectsCube, name: 'projectsCube' },
        { cube: creditsCube, name: 'creditsCube' }
    ];

    intersects.forEach(item => {
        if (item.cube && raycaster.intersectObject(item.cube).length > 0) {
            console.log(`Clicked on: ${item.name}`);
            moveToCube(item.name);
        }
    });
}

// Function to move the camera to the predefined position
function moveToCube(cubeName) {
    const duration = 2000;
    let targetPosition, targetRotation;

    switch (cubeName) {
        case 'aboutmeCube':
            if (Monitor) {
                targetPosition = {
                    position: { x: Monitor.position.x, y: Monitor.position.z + 1.5, z: Monitor.position.y + 3 }, // Zoom in more
                    target: { x: Monitor.position.x, y: Monitor.position.z, z: Monitor.position.y }
                };
                targetRotation = { y: camera.rotation.y - Math.PI / 2 }; // Rotate 90 degrees counterclockwise
            }
            break;
        case 'experienceCube':
            if (arcadeMaterial) {
                targetPosition = {
                    position: { x: arcadeMaterial.position.x, y: arcadeMaterial.position.z + 1.5, z: arcadeMaterial.position.y + 3 },
                    target: { x: arcadeMaterial.position.x, y: arcadeMaterial.position.z, z: arcadeMaterial.position.y }
                };
                targetRotation = { y: camera.rotation.y - Math.PI / 2 };
            }
            break;
        case 'projectsCube':
            if (TVMaterial) {
                targetPosition = {
                    position: { x: TVMaterial.position.x, y: TVMaterial.position.z + 1.5, z: TVMaterial.position.y + 3 },
                    target: { x: TVMaterial.position.x, y: TVMaterial.position.z, z: TVMaterial.position.y }
                };
                targetRotation = { y: camera.rotation.y - Math.PI / 2 };
            }
            break;
        default:
            console.error(`No camera position defined for cube: ${cubeName}`);
            return;
    }

    if (targetPosition) {
        new TWEEN.Tween(camera.position)
            .to(targetPosition.position, duration)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .start();

        new TWEEN.Tween(camera.rotation)
            .to(targetRotation, duration)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .start();

        new TWEEN.Tween(controls.target)
            .to(targetPosition.target, duration)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onUpdate(() => {
                controls.update();
            })
            .start();
    } else {
        console.error(`No camera position defined for cube: ${cubeName}`);
    }
}

// Add ambient light
const ambientLight = new THREE.AmbientLight(0xffffff, 20);
scene.add(ambientLight);

// Handle window resize
window.addEventListener('resize', () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
});

// Animation loop
function animate() {
    requestAnimationFrame(animate);
    TWEEN.update();
    controls.update();
    renderer.render(scene, camera);
}
animate();
